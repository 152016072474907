import React, { useContext, Fragment } from "react"

import Dropzone from "elements/Dropzone"
import DocumentsCardRow from "elements/Dropzone/DocumentsCardRow"

import { AppContext } from "../../../context/AppContext"

const EncodeUploadReceiptDropzone = props => {
  const { state, dispatch } = useContext(AppContext)
  let receiptUploadState = state.receiptUpload

  const file = receiptUploadState?.document.find(document =>
    document.name.startsWith(props.docType)
  )
  const files = receiptUploadState?.document.filter(document =>
    document.name.startsWith(props.docType)
  )

  const handleUploadSuccess = ({ file }) => {
    let tempDocuments = [...receiptUploadState?.document]
    tempDocuments.push(file)

    dispatch({
      type: "SAVE_DOCUMENT",
      payload: file,
    })

    dispatch({
      type: "SAVE_RECEIPT_UPLOAD_STATE",
      payload: { document: tempDocuments },
    })
  }

  const handleDeleteDocument = (index, fileName) => {
    let tempDocuments = [...receiptUploadState?.document]
    tempDocuments = tempDocuments.filter(document => document.name !== fileName)
    dispatch({
      type: "SAVE_RECEIPT_UPLOAD_STATE",
      payload: { document: tempDocuments },
    })

    dispatch({
      type: "SAVE_DOCUMENTS",
      payload: [...tempDocuments],
    })
  }

  return (
    <Fragment>
      {files && (
        <div className="mb-1">
          {files.map(
            (document, index) =>
              file && (
                <DocumentsCardRow
                  index={index}
                  fileName={document.name}
                  oldFileName={document.oldname}
                  handleDeleteDocument={handleDeleteDocument}
                  file={document}
                />
              )
          )}
        </div>
      )}
      {files.length < props.maxFiles && (
        <Dropzone
          currentFilesList={[
            ...receiptUploadState?.document,
            ...state?.documents,
          ]}
          docType={props.docType || "RX"}
          icon={props.icon}
          label={props.label}
          onUploadSuccess={handleUploadSuccess}
          maxFileCount={51}
        />
      )}
    </Fragment>
  )
}

export default EncodeUploadReceiptDropzone
