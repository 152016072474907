import * as Yup from "yup"

import { GATSBY_PURCHASE_DATE_YEAR } from "gatsby-env-variables"
import { REQUIRED_MESSAGE, INVALID_YEAR, yearNow } from "services/validations"

let isListItemValid = false

export const encodeValidationSchema = Yup.object().shape({
  salesInvoiceNumber: Yup.string().required(REQUIRED_MESSAGE),
  subdistributor: Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_MESSAGE)
      .nullable(),
  }),
  purchaseDate: Yup.object({
    month: Yup.object().shape({
      value: Yup.string()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    date: Yup.object().shape({
      value: Yup.number()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    year: Yup.number()
      .min(GATSBY_PURCHASE_DATE_YEAR, INVALID_YEAR)
      .required(REQUIRED_MESSAGE),
  }),
  lineItems: Yup.array()
    .compact(item => {
      return item.isOrdered.length === 0
    })
    .of(
      Yup.object().shape({
        tabletsOrdered: Yup.number().when("isOrdered", {
          is: isOrdered => {
            return isOrdered.length > 0
          },
          then: Yup.number()
            .min(1, "Please enter a valid total")
            .required(REQUIRED_MESSAGE),
        }),
      })
    )
    .required(REQUIRED_MESSAGE),
  // lineItems: Yup.array().test("lineItems", REQUIRED_MESSAGE, lineItemsList => {
  //   isListItemValid = lineItemsList.some(
  //     lineItemsList => lineItemsList.isOrdered.length > 0
  //   )
  //   return isListItemValid
  // }),
  // lineItems: Yup.array().of(
  // Yup.object().shape({
  //   tabletsOrdered: Yup.string().when("isOrdered", {
  //     is: isOrdered => isOrdered.length > 0,
  //     then: Yup.string().required(REQUIRED_MESSAGE),
  //   }),
  // })
  // ),
})
