import React, { Fragment, useContext } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"
import moment from "moment"

import Message from "elements/Message"
import ActionButtons from "elements/ActionButtons"
import UploadGuidelines from "elements/UploadGuidelines"
import EncodeUploadReceiptDropzone from "./EncodeUploadReceiptDropzone"

import { AppContext } from "../../../context/AppContext"
import { encodeReceiptForm } from "../utils/encodeReceiptForm"
import { generateFormField } from "../../Elements/Form/services/form"
import { encodeValidationSchema } from "../utils/encodeValidationSchema"

import { isFutureDate } from "../../../services/general"

const EncodeReceiptForm = () => {
  const { state, dispatch } = useContext(AppContext)
  let encodeReceiptState = state.receiptUpload

  const handleReceiptSave = (values, { setErrors }) => {
    let formErrors = {}
    let { purchaseDate } = values

    let receiptPayload = { ...values, document: encodeReceiptState?.document }

    let { month, date, year } = purchaseDate
    let validStartDate = moment("Sep 21 2021").toDate()
    let parsedPurchaseDate = moment(
      `${month.value} ${date?.value} ${year}`
    ).toDate()

    if (parsedPurchaseDate <= validStartDate) {
      formErrors.purchaseDate = {
        month: { value: "Please enter a valid purchase date." },
        date: { value: "Please enter a valid purchase date." },
      }
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
    } else {
      let tempReceipts = state?.medicineAvailment?.receipts
      tempReceipts.push(receiptPayload)

      dispatch({
        type: "SAVE_MEDICINE_AVAILMENT",
        payload: { receipts: tempReceipts },
      })

      dispatch({
        type: "RESET_RECEIPT_UPLOAD_STATE",
      })

      navigate("/giotrif/medicine-availment/receipt")
    }
  }

  return (
    <Fragment>
      <Message>
        <b>Valid sales invoices should:</b>
        <div className="content">
          <ul>
            <li className="pb-0">Have the patient’s name,</li>
            <li className="pb-0">
              Have a purchase date after September 22, 2021,
            </li>
            <li className="pb-0">Have a valid invoice number, and</li>
            <li className="pb-0">Be uploaded only once.</li>
          </ul>
        </div>
      </Message>
      <Formik
        onSubmit={handleReceiptSave}
        initialValues={encodeReceiptState}
        validationSchema={encodeValidationSchema}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            <UploadGuidelines guideline="sales_invoice_guideline" />
            <EncodeUploadReceiptDropzone
              docType="si"
              label="Upload Sales Invoice Photo or File"
              icon="document"
              maxFiles={10}
            />
            {encodeReceiptForm.map(field => {
              if (!field?.referenceAnswer) {
                return generateFormField({
                  formFields: encodeReceiptForm,
                  formField: field,
                  values,
                  setFieldValue,
                  errors,
                })
              }
              return null
            })}
            <ActionButtons
              back={{
                label: "Back",
                link: "/giotrif/medicine-availment/receipt",
              }}
              submit={{
                label: "Save",
                disabled: encodeReceiptState?.document?.length === 0,
              }}
            />
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default EncodeReceiptForm
