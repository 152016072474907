export const encodeReceiptForm = [
  {
    type: "text",
    name: "salesInvoiceNumber",
    label: "Sales Invoice Number",
    placeholder: "123456789ABCDEF",
    maxLength: 15,
    isRequired: true,
  },
  {
    type: "select",
    name: "subdistributor",
    label: "Subdistributor",
    placeholder: "Globo Asiatico Enterprises Inc.",
    options: [
      {
        value: "Globo Asiatico Enterprises Inc.",
        label: "Globo Asiatico Enterprises Inc.",
      },
      {
        value: "Onco Care Pharma Corporation",
        label: "Onco Care Pharma Corporation",
      },
    ],
    isRequired: true,
  },
  {
    type: "date",
    name: "purchaseDate",
    label: "Purchase Date",
    isRequired: true,
  },
  {
    type: "lineItem",
    name: "lineItems",
    label: "Line Items",
    inputLabel: "Tablets Ordered",
    fields: [
      "Afatinib (Giotrif) 30mg Tablet",
      "Afatinib (Giotrif) 40mg Tablet",
    ],
  },
]
