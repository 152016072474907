import React, { Fragment } from "react"

import Layout from "../Layout/Layout"
import Container from "../Layout/Container"
import EncodeReceiptForm from "./components/EncodeReceiptForm"

const ReceiptUpload = () => {
  return (
    <Fragment>
      <Layout
        title="Upload Proof of Purchase"
        seoTitle="Upload Proof of Purchase"
      >
        <Container isCentered>
          <EncodeReceiptForm />
        </Container>
      </Layout>
    </Fragment>
  )
}

export default ReceiptUpload
